.domain-container {
    padding: 50px;
    background-color: #ffffff;
}

.domain-content {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
}

.column {
    width: calc(47% - 20px);
    margin-right: 20px;
    background-color: #f5f5f5;
    padding: 20px;
}

.card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.card:hover {
    transform: scale(1.03);
}

h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333333;
}

p {
    font-size: 16px;
    color: #666666;
    line-height: 1.6;
}

