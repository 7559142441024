.about-us-container {
    padding: 50px;
    background-color: #ffffff;
  }
  
  .about-us-heading {
    text-align: center;
    margin-bottom: 20px;
    color: #333333;
  }
  
  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .member {
    width: 45%;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .member h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333333;
  }
  
  .member p {
    color: #666666;
  }

  .member {
    width: 20%; /* Adjust as needed */
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    text-align: center;
    display: inline-block; /* Keep members in the same row */
  }

  .supervisors {
    text-align: center;
  }

/* Style for FontAwesome icons */
.social-links a {
  text-decoration: none;
  margin: 0 10px;
}

.social-links a .icon {
  font-size: 24px;
  color: #007bff; /* Change the color as needed */
}

/* On hover effect for icons */
.social-links a:hover .icon {
  color: #0056b3; /* Change the hover color as needed */
}