.contact-container {
  padding: 2rem;
  background-color: white;
}

.contact-heading {
  text-align: center;
  margin: 70px;
  font-family: Arial, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
}

.or-divider {
  color: white;
  text-align: center;
  margin: 30px;
}

.contact-form {
  justify-content: center;
  text-align: center;
  display: flex;
  color: rgb(0, 0, 0);
}

.contact-textfield,
.contact-textarea {
  background: white;
  color: black;
}

.contact-textfield {
  width: 400px;
}

.contact-btn {
  background: goldenrod;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-btn:hover {
  background-color: darkgoldenrod;
}

@media screen and (max-width: 768px) {
  .contact-container {
    padding: 2rem;
  }

  .contact-heading {
    font-size: 35px;
  }

  .contact-right {
    display: flex;
    flex-direction: row;
  }
}