.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: #ffffff; /* Vibrant blue background color */
  color: #fff; /* White text color */
}

.logo-container {
  flex: 1;
}

.logo {
  max-width: 47%;
  height: auto;
}

.header-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.header-list li {
  margin: 0 1rem;
}

.header-list li a {
  text-decoration: none;
  color: #000000; /* White text color */
  font-size: 1rem; /* Normal font size */
  font-weight: bold;
  transition: color 0.3s;
}

.header-list li a:hover {
  color: #16c068; /* Bright yellow hover color */
}

@media (max-width: 768px) {
  .header-list {
    display: none;
  }

  /* Additional styling for mobile menu icon */
  .header-container img {
    cursor: pointer;
  }
}