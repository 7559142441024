.skills-container {
    padding: 1rem;
  }
  
  .skills-heading {
    text-align: center;
    font-family: Arial, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(0, 0, 0);
  }
  
  .skills-heading2 {
    text-align: center;
    font-family: Arial, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: rgb(0, 0, 0);
  }
  
  .skill-content {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    justify-content: center;
    /* Apply special styles here */
  }
  
  .skill-slide {
    border: 1px solid goldenrod;
  }
  
  .skill-card-content {
    text-align: center;
    /* Apply special styles here */
  }
  
  .skill-card-content > :nth-child(1) {
    text-align: center;
    /* Apply special styles here */
  }
  
  .skill-card-content > :nth-child(2) {
    text-align: center;
    /* Apply special styles here */
  }
  
  .skill-card-content > :nth-child(3) {
    text-align: center;
    /* Apply special styles here */
  }
  
  @media screen and (max-width: 768px) {
    .skills-container {
      padding: 2rem;
    }
  
    .skills-heading {
      font-size: 35px;
    }
  
    .skill-content {
      display: flex;
      flex-direction: column;
    }
  
    .skills-slide {
      width: fit-content;
      height: fit-content;
    }
  }  