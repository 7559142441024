.achievements-container {
    padding: 50px;
    background-color: #ffffff;
  }
  
  .achievements-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .achievements-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .achievement {
    width: 45%;
    margin-bottom: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
  }
  
  .achievement h2 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333333;
  }
  
  .achievement p {
    color: #666666;
  }  