.milestone-container {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 40px 0;
  }
  
  .milestone-content {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .milestone-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .milestone-card {
    display: flex;
    align-items: center;
    margin: 20px 0;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    overflow: hidden;
    width: 80%;
  }
  
  .milestone-side {
    position: relative;
  }
  
  .milestone-side-1 {
    width: 40px;
    height: 40px;
    background-color: #2cb819;
    border-radius: 50%;
  }
  
  .milestone-marker {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #fff;
    border-radius: 50%;
  }
  
  .arrow-down {
    top: -20px;
    bottom: auto;
    border-color: transparent transparent #ccc transparent;
  }
  
  .milestone-side-2,
  .milestone-side-3 {
    padding: 0 20px;
  }
  
  /* ... (previous CSS) ... */

.milestone-title {
    font-weight: bold;
    font-size: 17px; /* Adjusted font size */
    color: #333;
  }
  
  .milestone-date {
    color: #555;
    font-size: 13px; /* Adjusted font size */
  }
  
  /* ... (previous CSS) ... */  
  
  h2 {
    margin-bottom: 20px;
    color: #333;
  }

  .up-arrow {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    border-style: solid;
    border-width: 10px 10px 0;
    border-color: #007bff transparent transparent transparent;
  }

  .arrow-down {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 20px;
    height: 20px;
    background-color: #007bff;
    border: none;
  }
  
  .arrow-down::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    background-color: #fff;
  }

  /* Add this to your CSS */

/* .milestone-card {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease, transform 0.6s ease;
  }
  
  .milestone-card.active {
    opacity: 1;
    transform: translateY(0);
  } */
  
  /* Add this to your CSS */

.milestone-card:hover {
    transform: scale(1.05);
    transition: transform 0.3s ease;
  }