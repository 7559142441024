.hero-container {
  display: flex;
  align-items: center;
}

.image-container {
  position: relative;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
}

.hero-heading {
  font-size: 2rem;
  margin: 1rem 0;
  color: #fff;
}

.hero-subheading {
  font-size: 1.5rem;
  margin-bottom: 2rem;
  color: #fff;
}

.btn-hireme {
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.welcome-text {
  font-weight: bold;
  font-size: 1.5rem;
  color: #fff;
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 3;
}

.image1 {
  width: 100%;
  border-radius: 1%;
}