.header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sub-header {
    margin-bottom: 10px;
  }
  
  .button-container {
    text-align: center;
    margin-bottom: 20px; /* Added margin for spacing between button containers */
  }
  
  .button {
    background-color: #007BFF !important;
    color: #FFFFFF !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 8px !important;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .button img {
    margin-right: 10px;
    max-height: 30px; /* Adjust as needed */
  }
  
  .button:hover {
    background-color: #0056b3 !important;
  }  

  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .pdf-icon {
    max-width: 100px; /* Adjust as needed */
  }
  
  .file-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .file-name {
    font-size: 18px;
    margin: 0 10px;
  }
  
  .download-link {
    font-size: 18px;
    cursor: pointer;
    color: #007BFF;
  }
  
  .download-link:hover {
    text-decoration: underline;
  }

  .file-row {
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }

  .file-container {
    padding: 10px; /* Add your desired padding value */
  }